import React from "react"
import Layout from "../components/layout"
import ProductSelector from "../components/productSelector"
import { graphql, navigate } from "gatsby"
import styles from "./index.module.scss"

const gotoLink = (link) => {
  navigate("/" + link + "/")
}

export default function Home({ data }) {
  const post = data.allWpPost.edges
  return (
    <Layout>
      <div className={styles.articles}>
        {post.map(({ node }) => (
          <div style={{
            backgroundImage: `url(${node.images.image.localFile.childImageSharp.fluid.src})`,
            backgroundSize: `cover`
          }} className={styles.article} key={node.title}>
            <div className={styles.article_inside} style={{ background: `rgba(0, 0, 0, 0.6)` }}>
              <div className={styles.title}>{node.title}</div>
              <div className={styles.date}>Date: {node.date}</div>
              <div className={styles.button}>
                <button
                  className={"button is-rounded is-primary is-medium"}
                  onClick={() => gotoLink(node.slug)}>
                  Read Article
                </button>
              </div>
              <div className={styles.categories}>
                Categories: {
                node.categories.nodes.map(cat => cat.name)
                  .reduce((prev, curr) => [prev, ", ", curr])}
              </div>
            </div>
          </div>
        ))}
      </div>
      <div className={styles.more_posts}>
        <button className={"button is-rounded is-primary is-large"} onClick={() => gotoLink("posts/1")}> Read More
        </button>
      </div>
      <ProductSelector article={"home-blog"}/>
    </Layout>
  )
}


export const query = graphql`
    query {
        allWpPost(limit: 5, sort: {order: DESC, fields: date}) {
            edges {
                node {
                    slug
                    title
                    date(formatString: "MM-DD-YYYY")
                    categories {
                        nodes {
                            name
                        }
                    }
                    images{
                        image{
                            altText
                            localFile {
                                childImageSharp {
                                    fluid(maxWidth: 1024) {
                                        ...GatsbyImageSharpFluid
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`
