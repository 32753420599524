import React, { useState } from "react"
import styles from "./productSelector.module.scss"

const ProductSelector = ({article}) => {
  const [disabledButtonForm, updateDisabled] = useState("disabled")
  const [product, updateProduct] = useState("medicare")
  const [zipCode, updateZipCode] = useState("")
  const validateZipcode = (zipcode) => {
    fetch(process.env.GEO_API + zipcode)
      .then(function(data) {
        if (data.status === 204) {
          updateDisabled("disabled")
        } else {
          updateDisabled("")
        }
      })
      .catch(function(error) {
          updateDisabled("disabled")
        }
      )
  }

  const handleChange = (e) => {
    if (e.target.name === "zipcode") {
      if (e.target.value.length === 5) {
        validateZipcode(e.target.value)
        updateZipCode(e.target.value)
      } else {
        updateDisabled("disabled")
      }
    }
    if (e.target.name === "product") {
      updateProduct(e.target.value)
    }
  }

  const handleSubmit = () => {
    const redirecUrl = "https://quotes.insuranceplans.org/" + product + "?zipcode=" + zipCode + "&utm_source=blog&utm_medium=web&utm_campaign=blog&utm_term=" + article
    window.location.href = redirecUrl
  }
  return (
    <div className={styles.ProductSelector}>
      <h2>Let’s get you some insurance</h2>
      <div className={styles.form}>
        <div className={"control " + styles.select_box + " " + styles.hundred_percent}>
          <div className={"select is-large is-rounded " + styles.hundred_percent}>
            <select className={styles.hundred_percent + " " + styles.select_product + " " + styles.blue_input}
                    name={"product"} id={"product"} onBlur={handleChange}>
              <option>Medicare</option>
              <option>Health</option>
            </select>
          </div>
        </div>
        <div className={"control"}>
          <input
            className={"input is-large is-rounded " + styles.blue_input}
            type={"tel"}
            placeholder={"My Zipcode is"}
            name={"zipcode"}
            id={"zipcode"}
            onBlur={handleChange}
            onKeyUp={handleChange}
            maxLength={"5"}
          />
        </div>
      </div>
      <div className={"control " + styles.center}>
        <button
          className={"button is-primary is-rounded is-large"}
          disabled={disabledButtonForm}
          onClick={handleSubmit}
        >
          View Plans
        </button>
      </div>
      <h3>
        Prefer to talk? Call us at <a href="+18552683799">855-COVER-99</a>
      </h3>
    </div>
  )
}

export default ProductSelector
